export const ratingToColor = {
  methods: {
    ratingToColor(rating) {
      if (rating < 33) {
        return '#F6400C';
      } else if (rating >= 33 && rating < 66) {
        return '#F8A400';
      } else {
        return '#8BC34A';
      }
    },
    qualityToColor(quality) {
      if (quality == 'Bad') {
        return '#F6400C';
      } else if (quality == 'Average' || quality == 'Okay') {
        return '#F8A400';
      } else if (quality == 'Good') {
        return '#8BC34A';
      } else {
        return '#dddddd';
      }
    }
  }
};
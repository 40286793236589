<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <v-card-title class="py-2">Lowest Rated Products</v-card-title>
    <v-card-subtitle class="pb-2 pt-1">These products have the lowest overall rating. Action required to fix them.</v-card-subtitle>
    <v-divider/>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="products"
        :items-per-page="5"
        sort-by="overall_rating"
        item-key="product_id"
        hide-default-footer
        disable-sort
      >
      <template v-slot:[`header.core_rating`]="{ header }">
        <v-container @click="sortProducts(header.value)" class="hover-cursor">
          {{header.text}}
          <v-icon v-if="sort.includes(header.value)" small :style="`${sort.includes('+desc') ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

      <template v-slot:[`header.variant_rating`]="{ header }">
        <v-container @click="sortProducts(header.value)" class="hover-cursor">
          {{header.text}}
          <v-icon v-if="sort.includes(header.value)" small :style="`${sort.includes('+desc') ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

      <template v-slot:[`header.image_rating`]="{ header }">
        <v-container @click="sortProducts(header.value)" class="hover-cursor">
          {{header.text}}
          <v-icon v-if="sort.includes(header.value)" small :style="`${sort.includes('+desc') ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

      <template v-slot:[`header.website_rating`]="{ header }">
        <v-container @click="sortProducts(header.value)" class="hover-cursor">
          {{header.text}}
          <v-icon v-if="sort.includes(header.value)" small :style="`${sort.includes('+desc') ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

      <template v-slot:[`header.seo_rating`]="{ header }">
        <v-container @click="sortProducts(header.value)" class="hover-cursor">
          {{header.text}}
          <v-icon v-if="sort.includes(header.value)" small :style="`${sort.includes('+desc') ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

      <template v-slot:[`header.overall_rating`]="{ header }">
        <v-container @click="sortProducts(header.value)" class="hover-cursor">
          {{header.text}}
          <v-icon v-if="sort.includes(header.value)" small :style="`${sort.includes('+desc') ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

        <template v-slot:[`item.image`]="{ item }">
          <v-img :src="item.image_url" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="45px" width="45px" class="image-border"></v-img>
        </template>

        <template v-slot:[`item.name`]="{item}">
          <td class="px-0" style="max-width: 300px">
            <a :href="'/products/'+item.product_id" @click.prevent="goToProduct(item.product_id)">
              <div class="text-truncate txt font-weight-bold">{{item.name}}</div>
            </a>
          </td>
        </template>

        <template v-slot:[`item.core_rating`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" link :style="`background-color: ${ratingToColor(item.core_rating)}20; border: 1px solid ${ratingToColor(item.core_rating)};`" @click="openBreakdown(item, 'Core')"><b>{{ item.core_rating }}%</b></v-chip>
            </template>
            <span v-html="tooltips['core']"></span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.variant_rating`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" link :style="`background-color: ${ratingToColor(item.variant_rating)}20; border: 1px solid ${ratingToColor(item.variant_rating)};`" @click="openBreakdown(item, 'Variant')"><b>{{ item.variant_rating }}%</b></v-chip>
            </template>
            <span v-html="tooltips['variant']"></span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.image_rating`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" link :style="`background-color: ${ratingToColor(item.image_rating)}20; border: 1px solid ${ratingToColor(item.image_rating)};`" @click="openBreakdown(item, 'Image')"><b>{{ item.image_rating }}%</b></v-chip>
            </template>
            <span v-html="tooltips['image']"></span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.website_rating`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" link :style="`background-color: ${ratingToColor(item.website_rating)}20; border: 1px solid ${ratingToColor(item.website_rating)};`" @click="openBreakdown(item, 'Website')"><b>{{ item.website_rating }}%</b></v-chip>
            </template>
            <span v-html="tooltips['website']"></span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.seo_rating`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" link :style="`background-color: ${ratingToColor(item.seo_rating)}20; border: 1px solid ${ratingToColor(item.seo_rating)};`" @click="openBreakdown(item, 'SEO')"><b>{{ item.seo_rating }}%</b></v-chip>
            </template>
            <span v-html="tooltips['seo']"></span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.overall_rating`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" link :style="`background-color: ${ratingToColor(item.overall_rating)}70; border: 1px solid ${ratingToColor(item.overall_rating)};`" @click="openBreakdown(item, 'Core')"><b>{{ item.overall_rating }}%</b></v-chip>
            </template>
            <span v-html="tooltips['overall']"></span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn text class="rounded-lg pa-3 mr-2" max-height="32" @click="$emit('update:show_breakdown', true); $emit('update:breakdown_product', item.product_id)"><span style="text-decoration: underline;">Details</span></v-btn>
          <a :href="'/products/'+item.product_id" @click.prevent>
            <v-btn class="rounded-lg" max-height="32" @click="goToProduct(item.product_id)">Edit</v-btn>
          </a>
        </template>
      </v-data-table>
      <v-divider/>
      <div class="text-center">
        <v-container class="pa-0">
          <v-row justify="center">
            <v-col cols=11>
              <v-container class="max-width pa-0">
                <v-pagination v-model="page" :length="pageCount" @input="getLowestRatedProducts()"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { productsLogic } from "@/mixins/productsMixin.js";
import { ratingToColor } from '@/mixins/ratingToColorMixin.js';
export default {
  mixins: [productsLogic, ratingToColor],
  data() {
    return {
      headers: [
        { value: 'image', sortable: false, width: '77px' },
        { text: 'Name', value: 'name', tooltip: 'The name of the product.' },
        { text: 'Core Rating', value: 'core_rating', align: 'center' },
        { text: 'Variant Rating', value: 'variant_rating', align: 'center' },
        { text: 'Image Rating', value: 'image_rating', align: 'center' },
        { text: 'Website Rating', value: 'website_rating', align: 'center' },
        { text: 'SEO Rating', value: 'seo_rating', align: 'center' },
        { text: 'Overall Rating', value: 'overall_rating', align: 'center' },
        { value: 'action', width: '198px' },
      ],
      tooltips: {
        core: `Quality of product name, brand description, and product images. Click to view core breakdown.`,
        variant: `Quality of variant barcode and images. Click to view variant breakdown.`,
        image: `Quality of image alt text, resolution, and size. Click to view image breakdown.`,
        website: `Quality of localized name, description, and category/tags. Click to view website breakdown.`,
        seo: `Quality of meta name and description. Click to view SEO breakdown.`,
        overall: `Combination of all rating values, determining overall product quality.`,
      },
      sort: '',
      page: 1,
      pageCount: 0,
      products: []
    }
  },
  computed: {
    sortParam() {
      return this.sort ? `&sort=${this.sort}` : '';
    }
  },
  mounted() {
    this.getLowestRatedProducts();
  },
  methods: {
    sortProducts(type) {
      if (this.sort === type) {
        this.sort += '+desc';
      } else if (this.sort === `${type}+desc`) {
        this.sort = "";
      } else {
        this.sort = type;
      }
      this.getLowestRatedProducts();
    },
    openBreakdown(product, tab) {
      this.$emit('update:show_breakdown', true);
      this.$emit('update:breakdown_product', product.product_id)
      this.$emit('update:breakdown_tab', tab)
    },
    getLowestRatedProducts() {
      this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/metrics/lowest-rated-products?page=${this.page}${this.sortParam}`)
        .then(result => {
          this.pageCount = result.data.pages;
          this.products = result.data.products;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
</script>

<style scoped>
a { 
  text-decoration: none;
  color: rgb(0, 0, 0) !important;
} 
.txt:hover {
  text-decoration: underline;
}
</style>
<template>
  <div>
    <StepperDialog ref="create_dialog" :title="title" max_width="65%" :steps="3" :flat="flat">
      <template #cancel-action v-if="!showOptions"><span/></template>
      <template #confirm-action="{ options }">
        <v-menu open-on-hover bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="rounded-lg" outlined color="primary" v-bind="attrs" v-on="on" :disabled="!(productDetailsValid && variantDetailsValid)" @click="options.confirm('normal')">Save</v-btn>
          </template>

          <v-list nav v-if="showOptions">
            <v-list-item link @click="options.confirm('another')">
              <v-list-item-content>
                <v-list-item-title>Save & New</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="options.confirm('close')">
              <v-list-item-content>
                <v-list-item-title>Save & Close</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #step_1_header><span>Product Details</span></template>
      <template #step_1_content="">
        <v-row style="padding-top: 10px">
          <!-- Product Name -->
          <v-col cols="12" sm="6">
            <v-text-field 
              v-model="product.name"
              :rules="product_name_rules"
              label="Product name"
              required
              outlined
            ></v-text-field>
          </v-col>
          <!-- Brand Selecter -->
          <v-col cols="12" sm="6">
            <BrandSelect
              v-model="product.brand"
              :brands="brands"
              :access="access"
            ></BrandSelect>
          </v-col>
        </v-row>
        <!-- Product Description -->
        <CKEditorWrapper v-model="product.description" :access="access"/>
      </template>

      <template #step_2_header><span>Product Variants</span></template>
      <template #step_2_content="">
        <!-- Product Type Selector -->
        <div class="text-center" style="padding-top: 20px;">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="9" sm="6">
                <v-btn-toggle v-model="product.productType" tile color="grey" group mandatory>
                  <v-btn value="single">Single Product</v-btn>
                  <v-btn value="variant">Variant Product</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <!-- Variant Product -->
        <v-container v-if="product.productType === 'variant'">
          <!-- Variant Type Selecter -->
          <v-row justify="center">
            <v-col cols="9" sm="3" v-if="product.productType === 'variant'">
              <v-combobox
                v-model="product.variant_type"
                :rules="variant_type_rules"
                :items="['Colour', 'Size', 'Material', 'Style', 'Device']"
                label="Variant Type"
                required
                outlined
                clearable
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row v-for="(variant, index) in product.variants" :key="index" justify="center">
            <v-col cols="12" md="4">
              <v-text-field 
                v-model="variant.name"
                :rules="variants_name_rules"
                label="Name"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="variant.sku"
                :rules="variants_sku_rules"
                label="Sku"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="variant.barcode"
                :rules="variants_barcode_rules"
                label="Barcode"
                required
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-btn fab @click="removeVariant(index)"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-tooltip bottom :disabled="!skuLimitMet">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn style="margin-bottom: 10px" small @click="addVariant()" :disabled="skuLimitMet">Add Another Variant</v-btn>
                </div>
              </template>
              <span>You have reached your sku limit.</span>
            </v-tooltip>
          </v-row>
        </v-container>

        <!-- Single Product -->
        <v-container v-else-if="product.productType === 'single' || !product.productType">
          <v-row v-for="(variant, index) in product.variants" :key="index" justify="center">
            <v-col cols="12" md="3">
              <v-text-field
                v-model="variant.sku"
                :rules="variants_sku_rules"
                label="Sku"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="variant.barcode"
                :rules="variants_barcode_rules"
                label="Barcode"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #step_3_header><span>Product Images</span></template>
      <template #step_3_subheader><small>Optional</small></template>
      <template #step_3_content="" class="pt-3 pb-0 px-0">
        <ImageUploadCard
          style="box-shadow: none;"
          :images.sync="product.images"
          hide_header
          hide_actions
        ></ImageUploadCard>
      </template>

    </StepperDialog>
  </div>
</template>

<script>
import CKEditorWrapper from '@/components/CKEditorWrapper.vue'
import { brandsProduct, brandsCore } from "@/mixins/brandsMixin.js";
import { productsLogic } from "@/mixins/productsMixin.js";
import { metricsLogic } from "@/mixins/metricsMixin.js";
import BrandSelect from '@/components/brands/BrandSelect.vue';
import ImageUploadCard from '../ImageUploadCard.vue';
import StepperDialog from "../StepperDialog.vue"
export default {
  mixins: [brandsProduct, productsLogic, metricsLogic, brandsCore],
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Create Product'
    },
    showOptions: {
      type: Boolean,
      default: true
    },
    access: Object,
  },
  mounted() {
    if (this.flat) {
      this.show();
    }
  },
  data() {
    return {
      product: {
        name: '',
        description: '',
        brand: null,
        variant_type: null,
        variants: [{ name: null, sku: '' }],
        images: [],
        image_settings: this.$store.getters.getImageSettings,
      },
      addBrand: false,
      product_name_rules: [v => !!v || 'Name is required'],
      product_brand_rules: [v => !!v || 'Brand is required'],
      variant_type_rules: [v => !!v || 'Variant Type is required'],
      variants_sku_rules: [
        v => !!v || 'SKU is required',
        v => (v || '').length <= 32 || 'SKU can only be 32 characters long'
      ],
      variants_barcode_rules: [v => (v || '').length <= 20 || 'Barcode can only be 20 characters long'],
      variants_name_rules: [v => !!v || 'Variant name is required'],
      config: {
        btns: [
          ['undo', 'redo'],
          ['formatting'],
          ['strong', 'em', 'del'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['link', 'insertImage', 'noembed'],
          ['horizontalRule'],
          ['superscript', 'subscript'],
          ['viewHTML'],
          ['fullscreen'],
        ]
      }
    };
  },
  components: {
    BrandSelect,
    CKEditorWrapper,
    ImageUploadCard,
    StepperDialog
  },
  computed: {
    productDetailsValid() {
      return this.product.name !== '' && this.product.brand !== null;
    },
    variantDetailsValid() {
      return this.allVariantsValild && (this.product.productType !== 'single' ? this.variantProductTypeSelected : true)
    },
    skuLimitMet() {
      return this.$store.getters.getOrganizationSettings.sku_limit <= this.$store.getters.getOrganizationState.sku_count + this.product.variants.length
    },
    variantProductTypeSelected() {
      return this.product.variant_type !== null
    },
    allVariantsValild() {
      let validVariants = 0
      for (var i in this.product.variants) {
        if(this.product.variants[i].sku !== '' && (this.product.productType !== 'single' ? this.product.variants[i].name !== '' : true)) {
          validVariants++;
        }
      }
      return validVariants === this.product.variants.length;
    }
  },
  methods: {
    show() {
      this.$refs.create_dialog.showDialog()
      .then(create_type => {
        this.create(create_type);
      })
      .catch(() => {
        this.finish();
      })
    },
    async create(action) {
      // Upload the product
      let product_id = await this.uploadProduct(this.product);

      // If no product was created close create dialog
      if (product_id === undefined) {
        this.finish();
      } else {
        // After create, generate product rating
        this.updateProductRating(product_id);

        // Behaviour based on action type of create
        switch (action) {
          case 'normal':
            this.$router.push(`/products/${product_id}`);
            break;
          case 'another':
            if (this.$store.getters.getOrganizationSettings.sku_limit <= this.$store.getters.getOrganizationState.sku_count) {
              this.finish();
            } else {
              this.finish(true);
            }
            break;
          case 'close':
            this.finish();
            break;
        }
      }
    },
    finish(display = false) {
      this.product.name = '';
      this.product.description = '';
      this.product.brand = null;
      this.product.variant_type = null;
      this.product.variants = [{ name: '', sku: '', barcode: ''}];
      this.product.images = [];
      if (display) {
        this.show();
        this.$refs.create_dialog.resetAllSteps();
      }
    },
    removeVariant(index) {
      if (this.product.variants.length > 1) {
        this.product.variants.splice(index, 1);
      }
    },
    addVariant() {
      let previous_variant = this.product.variants[this.product.variants.length - 1];
      // Check that the previoud variant is not empty
      if (previous_variant.name !== "" && previous_variant.sku !== "") {
        this.product.variants.push({ name: "", sku: "", barcode: "" });
      }
    },
  }
};
</script>
<template>
  <div>
    <v-row>
      <v-col>
        <RatingBreakdownDialog
          :show.sync="show_breakdown"
          :product_id="breakdown_product_id"
          :open_tab="breakdown_tab"
          :goTo="true"
        ></RatingBreakdownDialog>
        <ActionRequiredProducts
          :show_breakdown.sync="show_breakdown"
          :breakdown_product.sync="breakdown_product_id"
          :breakdown_tab.sync="breakdown_tab"
        ></ActionRequiredProducts>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=6>
        <WebsiteProductStatusesCard
          style="height: 100%;"
        ></WebsiteProductStatusesCard>
      </v-col>
      <v-col cols=6>
        <UnpublishedChangesProducts
          :websites="websites"
          style="height: 100%;"
        ></UnpublishedChangesProducts>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { websitesCore } from "@/mixins/websitesMixin.js";
import ActionRequiredProducts from './ActionRequiredProducts.vue';
import WebsiteProductStatusesCard from './WebsiteProductStatusesCard.vue';
import UnpublishedChangesProducts from './UnpublishedChangesProducts.vue';
import RatingBreakdownDialog from '../ratingBreakdown/RatingBreakdownDialog.vue';
export default {
  mixins: [websitesCore],
  components: {
    ActionRequiredProducts,
    WebsiteProductStatusesCard,
    UnpublishedChangesProducts,
    RatingBreakdownDialog,
  },
  data() {
    return {
      show_breakdown: false,
      breakdown_product_id: null,
      breakdown_tab: 'Core',
    }
  }
}
</script>
<template>
  <v-card elevation=4 outlined class="rounded-lg mb-3">
    <v-card-title>Admin Dashboard</v-card-title>
    <v-divider/>
    <v-card-text>
      Admins eyes only!
    </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>
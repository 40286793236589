<template>
  <v-dialog v-model="show" max-width="90%" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
    <v-card elevation="4" outlined class="rounded-lg" v-if="Object.keys(product).length > 0">
      <v-card-title>
        Rating Breakdown
        <v-spacer/>
        <v-chip :style="`background-color: ${ratingToColor(product.ratings.overall)}70; border: 1px solid ${ratingToColor(product.ratings.overall)};`"><b>Overall: {{ product.ratings.overall }}%</b></v-chip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-if="goTo" class="ml-2 rounded-lg" max-height="32" @click="$emit('update:show', false); goToProduct(product.product_id)">Edit</v-btn>
          </template>
          <span>Edit {{ product.name }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <RatingBreakdownTabs
          :current_tab.sync="current_tab"
          :product="product"
          :ratingToColor="ratingToColor"
          :qualityToColor="qualityToColor"
          v-on="$listeners"
        ></RatingBreakdownTabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import RatingBreakdownTabs from './RatingBreakdownTabs.vue';
import { metricsLogic } from "@/mixins/metricsMixin.js";
import { ratingToColor } from '@/mixins/ratingToColorMixin.js';
import { productsLogic } from "@/mixins/productsMixin.js";
export default {
  mixins: [metricsLogic, ratingToColor, productsLogic],
  components: { RatingBreakdownTabs },
  props: {
    show: Boolean,
    product_id: Number,
    open_tab: String,
    goTo: Boolean,
    alwaysRefresh: Boolean,
  },
  data() {
    return {
      current_tab: 'Core',
      product: {},
    }
  },
  watch: {
    async show(show) {
      if (show && (this.product_id !== this.product.product_id || this.alwaysRefresh)) {
        this.product = await this.getProductRating(this.product_id);
      }
    },
    open_tab(tab) {
      if(tab !== this.current_tab) {
        this.current_tab = tab;
      }
    }
  }
}
</script>
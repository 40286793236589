<template>
  <v-row>
    <v-col class="py-0">
      <v-list class="pa-0">
        <template v-for="(row, i) in core">
          <v-list-item :key="row.field" class="px-0">
            <v-list-item-content>
              <v-list-item-title v-text="row.field"></v-list-item-title>
              <v-list-item-subtitle v-text="row.value"></v-list-item-subtitle>
            </v-list-item-content>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" :style="`background-color: ${qualityToColor(row.quality)}70; border: 1px solid ${qualityToColor(row.quality)};`">
                  <b>{{ row.quality }}</b>
                  <v-icon right>mdi-help-circle-outline</v-icon>
                </v-chip>
              </template>
              <span v-html="row.rules"></span>
            </v-tooltip>
          </v-list-item>

          <v-divider v-if="i < core.length - 1" :key="i"/>
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    core: Array,
    qualityToColor: Function,
  }
}
</script>
<template>
  <v-window style="overflow: visible;">
    <!-- Tab Buttons -->
    <v-btn-toggle :value="current_tab" @change="$emit('update:current_tab', $event)" mandatory style="justify-content: space-evenly;" class="py-4" active-class="current-tab">
      <v-row>
        <v-col v-for="(tab, i) in tabs" :key="i">
          <v-btn elevation=4 class="button-style rounded-lg" style="white-space: pre;" :value="tab">{{ tab }} - <b :style="`color: ${ratingToColor(product.ratings[tab.toLowerCase()])}`">{{ product.ratings[tab.toLowerCase()] }}%</b></v-btn>
        </v-col>
      </v-row>
    </v-btn-toggle>
    <v-divider class="mt-1 pb-3"/>

    <!-- Individual Tabs -->
    <template v-for="(tab, i) in tabs">
      <RatingBreakdownSingle
        v-if="tab == current_tab && tab == 'Core'"
        :core="product[tab.toLowerCase()]"
        :qualityToColor="qualityToColor"
        :key="i"
      ></RatingBreakdownSingle>

      <RatingBreakdownMulti
        v-else-if="tab == current_tab"
        :current_tab="current_tab"
        :data="product[tab.toLowerCase()]"
        :selected_item.sync="selected_item"
        :qualityToColor="qualityToColor"
        :key="i"
      ></RatingBreakdownMulti>
    </template>
  </v-window>
</template>

<script>
import RatingBreakdownSingle from '@/components/ratingBreakdown/RatingBreakdownSingle.vue';
import RatingBreakdownMulti from './RatingBreakdownMulti.vue';
export default {
  props: {
    current_tab: String,
    product: Object,
    ratingToColor: Function,
    qualityToColor: Function,
  },
  components: {
    RatingBreakdownSingle,
    RatingBreakdownMulti
  },
  data() {
    return {
      selected_item: {},
      tabs: ['Core', 'Variant', 'Image', 'Website', 'SEO']
    }
  }
}
</script>
<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <PublishProductDialog
      ref="publish_dialog"
      :products="[selected_product]"
      :websites="websites"
    ></PublishProductDialog>
    <v-card-title class="py-2">Unpublished Changed Products</v-card-title>
    <v-card-subtitle class="pb-2 pt-1">These products have changed since last publish on the <b>{{ selected_website.name }}</b> website.</v-card-subtitle>
    <v-divider/>
    <v-card-text class="pa-2">
      <v-select
        v-model="selected_website"
        :items="websites"
        item-text="name"
        return-object
        dense
        outlined
        hide-details
      ></v-select>
    </v-card-text>
    <v-divider v-if="pages > 0"/>
    <v-card-text class="pa-0">
      <v-data-table
        v-if="pages > 0"
        :headers="headers"
        :items="products"
        :items-per-page="5"
        item-key="product_id"
        hide-default-footer
        disable-sort
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-img :src="item.image_url" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="45px" width="45px" class="image-border"></v-img>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <td class="px-0" style="max-width: 200px">
            <a :href="'/products/'+item.product_id" @click.prevent="goToProduct(item.product_id)">
              <div class="text-truncate txt font-weight-bold">{{item.name}}</div>
            </a>
          </td>
        </template>

        <template v-slot:[`item.last_updated`]="{item}">
          {{ new Date(`${item.last_updated.replaceAll('-', '/')} UTC`).toLocaleString() }}
        </template>

        <template v-slot:[`item.last_published`]="{item}">
          {{ new Date(`${item.last_published.replaceAll('-', '/')} UTC`).toLocaleString() }}
        </template>

        <template v-slot:[`item.action`]="{item}">
          <v-row class="px-0 ma-0">
            <v-btn class="rounded-lg px-2" max-height="32" @click="showPublishDialog(item)">Publish</v-btn>
            <v-spacer/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="rounded-lg px-2" max-height="32" @click="ignoreOnReport(item)">Remove</v-btn>
              </template>
              <span>Remove product from this report</span>
            </v-tooltip>
          </v-row>
        </template>
      </v-data-table>
      <v-divider/>
      <div class="text-center">
        <v-container class="pa-0">
          <v-row justify="center">
            <v-col cols=11>
              <v-container class="max-width pa-0">
                <v-pagination v-if="pages > 0" v-model="page" :length="pages" @input="getUnpublishedChangedProducts"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <p v-if="pages == 0" class="mx-auto font-weight-black grey--text text--lighten-1" style="position: absolute; top: 50%; bottom: 50%; left: 0; width: 100%; font-size: 3.5em;">All up to date!</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PublishProductDialog from '@/components/PublishProductDialog.vue';
import { productsLogic } from "@/mixins/productsMixin.js";
export default {
  mixins: [productsLogic],
  props: {
    websites: Array,
  },
  components: {
    PublishProductDialog,
  },
  data() {
    return {
      headers: [
        { value: 'image', sortable: false, width: '77px' },
        { text: 'Name', value: 'name' },
        { text: 'Last Updated', value: 'last_updated', align: 'center' },
        { text: 'Last Publish', value: 'last_published', align: 'center' },
        { value: 'action', align: 'center', width: '200px' },
      ],
      page: 1,
      pages: 0,
      products: [],
      selected_product: {},
      selected_website: {},
      show_publish: false,
    }
  },
  watch: {
    websites(websites) {
      this.selected_website = websites[0];
    },
    selected_website() {
      this.getUnpublishedChangedProducts();
    }
  },
  methods: {
    showPublishDialog(product) {
      this.getVariantsForPublish(product).then(() => {
        return this.$refs.publish_dialog.show();
      }).then(() => {
        this.getUnpublishedChangedProducts();
      });
    },
    getVariantsForPublish(product) {
      return this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/products/${product.product_id}/variants`)
        .then(result => {
          this.selected_product = { ...product, variants: result.data };
        })
        .catch(err => {
          console.log(err);
        });
    },
    ignoreOnReport(product) {
      this.$axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${product.product_id}/websites/${this.selected_website.website_id}/ignore-on-report`)
        .then(() => {
          this.getUnpublishedChangedProducts();
          this.$store.dispatch("showSnackbar", {text: "Removed Product Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
        });
    },
    getUnpublishedChangedProducts() {
      this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/websites/${this.selected_website.website_id}/unpublished-changed-products?page=${this.page}`)
        .then(result => {
          this.pages = result.data.pages;
          this.products = result.data.products;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
</script>

<style scoped>
a { 
  text-decoration: none;
  color: rgb(0, 0, 0) !important;
}
.txt:hover {
  text-decoration: underline;
}
</style>
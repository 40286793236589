<template>
  <v-card class="rounded-lg">
    <v-toolbar
      flat
      color="#007b7e"
      dark
    >
      <v-toolbar-title>Welcome to ItemFlo! Lets setup your account.</v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical v-model="selected_tab" color="#007b7e">
      <v-tab :disabled="$store.getters.getOrganizationState.website_count > 0">
        <v-icon left>
          {{ $store.getters.getOrganizationState.website_count > 0 ? 'mdi-check' : 'mdi-sitemap' }}
        </v-icon>
        <span :style="$store.getters.getOrganizationState.website_count > 0 ? 'text-decoration: line-through;' : ''">Add a website</span>
      </v-tab>
      <v-divider/>
      <v-tab :disabled="$store.getters.getOrganizationState.brand_count > 0">
        <v-icon left>
          {{ $store.getters.getOrganizationState.brand_count > 0 ? 'mdi-check' : 'mdi-label' }}
        </v-icon>
        <span :style="$store.getters.getOrganizationState.brand_count > 0 ? 'text-decoration: line-through;' : ''">Create a brand</span>
      </v-tab>
      <v-divider/>
      <v-tab :disabled="$store.getters.getOrganizationState.sku_count > 0">
        <v-icon left>
          {{ $store.getters.getOrganizationState.sku_count > 0 ? 'mdi-check' : 'mdi-barcode' }}
        </v-icon>
        <span :style="$store.getters.getOrganizationState.sku_count > 0 ? 'text-decoration: line-through;' : ''">Create a product</span>
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <CreateSite
              ref="create_website_dialog"
              :title="'Create your first website!'"
              :flat="true"
              :access="access"
              :showOptions="false"
              :selected_tab.sync="selected_tab"
            ></CreateSite>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Add Brand Dialog -->
            <BrandEditCard
              :show="true"
              :isUpdate="false"
              :flat="true"
              :selected_tab.sync="selected_tab"
            ></BrandEditCard>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <CreateProductDialog
              :flat="true"
              :title="'Create your first product!'"
              :showOptions="false"
              :access="access"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { websitesCore } from "@/mixins/websitesMixin.js";
import CreateProductDialog from '../products/CreateProductDialog.vue';
import CreateSite from "@/components/sites/CreateSite.vue"
import BrandEditCard from '@/components/brands/BrandEditCard.vue';
export default {
  mixins: [websitesCore],
  components: {
    CreateProductDialog,
    CreateSite,
    BrandEditCard
  },
  data() {
    return {
      access: this.$store.getters.getAccessLevel['websites'],
      tab: 0,
    }
  },
  mounted() {
    this.selected_tab = 0;
  },
  computed: {
    selected_tab: {
      get() {
        return this.tab;
      },
      set(new_value) {
        // Check if the new value can be set, otherwise increment
        switch(new_value) {
          case 0:
            if (this.$store.getters.getOrganizationState.website_count > 0) {
              this.selected_tab = new_value + 1;
            } else {
              this.tab = new_value;
            }
            break;
          case 1:
            if (this.$store.getters.getOrganizationState.brand_count > 0) {
              this.selected_tab = new_value + 1;
            } else {
              this.tab = new_value;
            }
            break;
          case 2:
            // No check needed on last
            // tab as if already complete the
            // first load screen will dissapear
            this.tab = new_value;
        }
      }
    }
  },
  methods: {
    changeTab(new_index) {
      this.selected_tab = new_index;
    }
  }
}
</script>
<template>
  <v-container fluid>
    <div v-if="loggedIn && !firstLoad">
      <EditorDashboard
        v-if="user.dashboard == 1"
      ></EditorDashboard>
      <CreatorDashboard
        v-if="user.dashboard == 2"
      ></CreatorDashboard>
      <AdminDashboard
        v-if="user.dashboard == 3"
      ></AdminDashboard>
    </div>
    <div v-else-if="loggedIn">
      <FirstLoadDashboard/>
    </div>
  </v-container>
</template>

<script>
import { userCore } from "@/mixins/userMixin.js";
import EditorDashboard from '@/components/home/EditorDashboard.vue';
import CreatorDashboard from '@/components/home/CreatorDashboard.vue';
import AdminDashboard from '@/components/home/AdminDashboard.vue';
import FirstLoadDashboard from '@/components/home/FirstLoadDashboard.vue';
export default {
  mixins: [userCore],
  components: {
    EditorDashboard,
    CreatorDashboard,
    AdminDashboard,
    FirstLoadDashboard
  },
  computed: {
    firstLoad() {
      return this.$store.getters.getOrganizationState.sku_count === 0 || this.$store.getters.getOrganizationState.website_count === 0 || this.$store.getters.getOrganizationState.brand_count === 0;
    }
  }
}
</script>
<template>
  <v-row>
    <v-col class="py-0">
      <v-list class="pa-0">
        <v-list-item v-if="Object.keys(selected_item).length !== 0" class="px-0">
          <v-select
            :value="selected_item"
            :items="data"
            :label="current_tab"
            return-object
            outlined
            hide-details
            class="py-4 px-0"
            @change="$emit('update:selected_item', $event)"
          >
            <template v-slot:item="{ item, on, attrs }" v-if="current_tab === 'Image'">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>{{ item.value[0].value }}</v-list-item-title>
                  <v-list-item-subtitle >{{ item.text }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-img :src="item.text" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="50px" width="50px" class="image-border" contain></v-img>
                </v-list-item-action>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }" v-if="current_tab === 'Image'">
              <v-list-item class="pa-0" style="width: 100%">
                <v-list-item-content class="py-0">
                  <v-list-item-title>{{ item.value[0].value }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="ma-0">
                  <v-img :src="item.text" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="50px" width="50px" class="image-border" contain></v-img>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-select>
        </v-list-item>

        <v-divider v-if="Object.keys(selected_item).length !== 0"/>

        <template v-for="(row, i) in selected_item.value">
          <v-list-item :key="row.field" class="px-0">
            <v-list-item-content>
              <v-list-item-title v-text="row.field"></v-list-item-title>
              <v-list-item-subtitle v-text="row.value"></v-list-item-subtitle>
            </v-list-item-content>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" :style="`background-color: ${qualityToColor(row.quality)}70; border: 1px solid ${qualityToColor(row.quality)};`">
                  <b>{{ row.quality }}</b>
                  <v-icon right>mdi-help-circle-outline</v-icon>
                </v-chip>
              </template>
              <span v-html="row.rules"></span>
            </v-tooltip>
          </v-list-item>

          <v-divider v-if="i < selected_item.value.length - 1" :key="i"/>
        </template>

        <v-list-item v-if="Object.keys(selected_item).length === 0" class="px-0">
          <v-list-item-content>
            <v-list-item-title>No Data</v-list-item-title>
            <v-list-item-subtitle>No {{ current_tab }} data found.</v-list-item-subtitle>
          </v-list-item-content>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" :style="`background-color: ${qualityToColor('N/A')}70; border: 1px solid ${qualityToColor('N/A')};`">
                <b>N/A</b>
                <v-icon right>mdi-help-circle-outline</v-icon>
              </v-chip>
            </template>
            <span v-html="'No data could be found for this selected item. Please edit the product further for additional details.'"></span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    current_tab: String,
    data: Array,
    selected_item: Object,
    qualityToColor: Function,
  },
  mounted: function() {
    this.$emit('update:selected_item', this.data.length ? this.data[0] : {});
  },
  watch: {
    data() {
      this.$emit('update:selected_item', this.data.length ? this.data[0] : {});
    }
  }
}
</script>
<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <v-card-title class="py-2">Website Product Statuses</v-card-title>
    <v-card-subtitle class="pb-2 pt-1">Current publish statuses of products on the <b>{{selected_website.name}}</b> website.</v-card-subtitle>
    <v-divider/>
    <v-card-text class="pa-2">
      <v-row dense>
        <v-col>
          <v-select
            v-model="selected_website"
            :items="websites"
            label="Website"
            item-text="name"
            return-object
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="auto" class="my-auto">
          <v-chip label outlined color="grey" text-color="black" style="height: 40px">Total Products: {{ statuses.total_products }}</v-chip>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider/>
    <v-card-text>
      <v-row>
        <v-col cols=4>
          <v-card hover class="rounded-lg" :style="`background-color: ${qualityToColor('Good')}30; border: 3px solid ${qualityToColor('Good')};`" @click="goToProducts('active');">
            <v-responsive aspect-ratio="1">
              <v-card-title class="justify-center text-xs-body-2 text-sm-h5 text-md-h4 text-lg-h1 text-xl-h1" style="height: 100%;">
                {{ statuses.active_products }}
              </v-card-title>
              <v-card-subtitle class="text-center text-overline mt-n8">
                Active
              </v-card-subtitle>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols=4>
          <v-card hover class="rounded-lg" :style="`background-color: ${qualityToColor('Okay')}30; border: 3px solid ${qualityToColor('Okay')};`" @click="goToProducts('inactive');">
            <v-responsive aspect-ratio="1">
              <v-card-title class="justify-center text-xs-body-2 text-sm-h5 text-md-h4 text-lg-h1 text-xl-h1" style="height: 100%;">
                {{ statuses.inactive_products }}
              </v-card-title>
              <v-card-subtitle class="text-center text-overline mt-n8">
                Inactive
              </v-card-subtitle>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols=4>
          <v-card hover class="rounded-lg" :style="`background-color: ${qualityToColor('Bad')}30; border: 3px solid ${qualityToColor('Bad')};`" @click="goToProducts('unpublished');">
            <v-responsive aspect-ratio="1">
              <v-card-title class="justify-center text-xs-body-2 text-sm-h5 text-md-h4 text-lg-h1 text-xl-h1" style="height: 100%;">
                {{ statuses.unpublished_products }}
              </v-card-title>
              <v-card-subtitle class="text-center text-overline mt-n8">
                Unpublished
              </v-card-subtitle>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { metricsLogic } from "@/mixins/metricsMixin.js";
import { websitesCore } from "@/mixins/websitesMixin.js";
import { ratingToColor } from '@/mixins/ratingToColorMixin.js';
export default {
  mixins: [metricsLogic, websitesCore, ratingToColor],
  data() {
    return {
      statuses: {},
      selected_website: {},
      search: this.$store.getters.getSearch
    }
  },
  watch: {
    websites(websites) {
      this.selected_website = websites[0];
    },
    async selected_website(website) {
      this.statuses = await this.getWebsiteProductStatuses(website.website_id);
    }
  },
  methods: {
    goToProducts(status) {
      this.search['website'] = this.selected_website.website_id;
      this.search['status'] = status;
      this.$router.push(`/products`);
    }
  }
}
</script>